import React from "react";
import { ContextProviderComponent } from "src/layouts/Context.js";

// import Cookies from "src/components/global/Cookies.js";

const HomePage = ({ data, children, pageContext }) => {
  return (
    <>
      <ContextProviderComponent>{children}</ContextProviderComponent>
    </>
  );
};

export default HomePage;
